@keyframes show {
  0% {
    opacity: 0;
    max-height: 0;
  }
  100% {
    opacity: 1;
    max-height: initial;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
    max-height: initial;
  }
  100% {
    opacity: 0;
    max-height: 0;
  }
}

.generate_course {
  width: 100%;
  color: white;

  .btn_curl {
    margin: 0 auto;
    display: none;
    align-items: center;
    width: fit-content;
    font-size: 14px;

    svg {
      transform: rotate(180deg);
      margin-left: 10px;
      transition: all .3s linear;

      path {
        stroke: var(--background_lighten-dark);
      }
    }

    &.open {
      svg {
        transform: rotate(0deg);
      }
    }
  }

  .header {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--background_lighten-dark);

    .title_block {
      .h1 {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        font-size: 24px;

        svg {
          margin-left: 15px;
        }
      }

      .text {
        font-size: 16px;
        color: #757A88;
      }
    }

    .header_buttons {
      display: flex;
      align-items: center;
    }

    .favorite {
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 52px;
      height: 52px;
      border-radius: 16px;
      background: var(--dark);
      cursor: pointer;

      svg {
        path {
          fill: #FFD23B;
          stroke: #FFD23B;
        }
      }
    }

    .generation_count-block {
      padding: 2px 4px 2px 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: fit-content;
      border-radius: 16px;
      background: var(--dark);

      .count {
        display: flex;
        align-items: center;

        span:first-child {
          margin-right: 7px;
          font-size: 36px;
          font-weight: 500;
          line-height: normal;
          color: white;
        }

        span:last-child {
          max-width: 70px;
          font-size: 14px;
          line-height: normal;
          color: #757A88;
        }
      }

      .icon {
        margin-left: 25px;
        padding: 12px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        background: var(--blue);
        border-radius: 12px;
        cursor: pointer;

        span {
          margin-left: 10px;
        }
      }
    }
  }

  .trajectory_block {
    margin-bottom: 30px;
    display: flex;
    width: 100%;
    transition: all .3s linear;

    .trajectory {
      display: flex;
      flex-direction: column;
      padding: 15px;
      width: 100%;
      border-radius: 24px;
      background: var(--dark);
      transition: all .3s linear;

      .top_settings {
        padding-bottom: 15px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--background_lighten-dark);

        .label {
          font-size: 18px;
          font-weight: 500;
        }

        .size_btn {
          padding: 4px 15px 4px 10px;
          display: flex;
          height: fit-content;
          border-radius: 24px;
          background: #8146FF;
          cursor: pointer;

          span {
            margin-right: 10px;
          }
        }

        .settings {
          padding: 4px 15px 4px 12px;
          display: flex;
          align-items: center;
          border-radius: 24px;
          background: #1D1E25;

          span {
            margin: 0 15px 0 10px;
            line-height: normal;
          }

          img:last-child {
            margin-top: 2px;
          }
        }
      }

      .info {
        /*margin-bottom: 12px;*/
        display: flex;
        align-items: center;

        .figure {
          margin-right: 10px;
          width: 10px;
          height: 10px;
          min-width: 10px;
          min-height: 10px;
          background: white;
          border-radius: 50%;

          &:nth-child(3) {
            margin: 0 10px;
            background: var(--text_color_dark);
          }
        }
      }

      .list_tags {
        padding-top: 15px;
        display: flex;
        flex-wrap: wrap;
        max-height: 100px;
        overflow-y: auto;
        transition: all .3s linear;
      }

      .btn_generate {
        width: 100%
      }

      &.open {
        .list_tags {
          max-height: 400px;
        }
      }

      &.width_50 {
        max-width: 49.5%;
        margin-right: 15px;
        /*  max-width: 100%;*/

        .top_settings {
          margin-bottom: 0;
        }
      }
    }

    .share_link {
      padding: 21px 23px;
      position: relative;
      width: 100%;
      height: 202px;
      border-radius: 30px;
      background: var(--dark);

      .text {
        width: 100%;
        padding-right: 40px;
        margin-bottom: 20px;
        font-size: 19px;
        font-weight: 500;
        line-height: normal;
      }

      .row {
        display: flex;
        width: 100%;
        align-items: center;
      }

      .present {
        position: absolute;
        top: 17px;
        right: 20px;

        img {
          width: 41px;
          height: 41px;
        }
      }

      .copy {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          font-size: 17px;
          font-weight: 500;
        }
      }

      .copy_btn {
        margin: 15px auto;
        padding: 13px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 230px;
        width: 100%;
        border-radius: 24px;
        background: var(--blue);
        cursor: pointer;
        user-select: none;

        span {
          margin-right: 10px;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .socials {
        margin: 15px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;

        img {
          cursor: pointer;

          &:first-child {
            margin-right: 20px;
          }
        }
      }
    }

    .banner {
      margin: 0 auto;
      width: fit-content;

      img {
        width: 100%;
        border-radius: 36px;
      }
    }

    &.entering {
      animation: show .8s forwards linear;
    }

    &.entered {
      animation: show .8s forwards linear;
    }

    &.exiting {
      animation: hide .4s forwards linear;
    }

    &.exited {
      animation: hide .4s forwards linear;
    }
  }

  .title_planning {
    margin: 30px 0 20px 0;
    font-size: 32px;
  }

  .player_auth {
    margin: 30px auto 0 auto;
    display: flex;
    width: 100%;
    max-width: 645px;

    .player_demo {
      iframe, video {
        border-radius: 24px;
      }
    }
  }

  .player {
    margin: 30px auto 0 auto;
    max-width: 645px;
    width: 100%;
    display: flex;

    .player_demo {
      iframe, video {
        border-radius: 30px;
      }
    }
  }

  .title_plan {
    font-size: 20px;
    margin: 0 0 5px 15px;
  }

  .list_planning {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    @media (max-width: 1293px) {
      justify-content: center;
    }

    .lang_changer {
      padding: 8px 11px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      max-height: 33px;
      background: #27292F;
      border: 1px solid var(--background_lighten-dark);
      border-radius: 16px;
      transition: all .3s;
      cursor: pointer;

      div {
        text-align: center;
        color: #757A88;
        transition: all .3s;
        cursor: pointer;

        &:hover,
        &.active {
          transition: all .3s;
          color: white;
        }
      }

      &.open {
        transition: all .3s;
        flex-direction: column;
        max-height: 80px;
        height: 100%;

        div:not(:last-child)  {
          padding-bottom: 2px;
          border-bottom: 1px solid var(--background_lighten-dark);
        }
      }
    }
  }

  .message_loading {
    width: fit-content;
    margin: 0 auto;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
  }

  .parent_traektory {
    margin-bottom: 30px;
    padding: 12px 12px 2px 12px;
    background: var(--dark);
    border-radius: 20px;
    transition: all .3s ease;

    .traektory {
      display: flex;
      flex-wrap: wrap;
      max-height: 80px;
      overflow: auto;
      transition: all .3s ease;

      .skill {
        padding: 6px 32px 8px 10px;
        margin-right: 5px;
        margin-bottom: 10px;
        display: flex;
        width: fit-content;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        line-height: 17px;
        border: 1px solid transparent;
        background: rgba(22, 21, 27, 0.65);
        border-radius: 29px;
        cursor: pointer;
        user-select: none;

        span {
          font-size: 14px;
          line-height: 16px;
          color: white;
        }

        img {
          position: absolute;
          top: 50%;
          right: 4px;
          transform: translateY(-50%);
          cursor: pointer;
        }

        &.active {
          border-color: #30E0A1;
        }
      }
    }
  }

  .pagination_all {
    margin: 5px auto;

    .ant-pagination {
      display: flex;
    }

    .ant-pagination-item {
      padding: 2px 3px;
      margin-right: 5px;
      background-color: transparent;
      border: none;

      a {
        border-radius: 8px !important;
        color: white;
        background-color: var(--dark);
        padding: 0 10px;
      }

      &-active {
        a {
          background-color: lighten(#343843, 20%);
        }

        border-color: transparent;
      }
    }

    li.ant-pagination-options {
      display: none;
    }

    li.ant-pagination-prev,
    li.ant-pagination-next {
      display: none;
    }

    li.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon {
      padding: 0;
      background-color: transparent;
      border: none;
    }

    span.ant-pagination-item-ellipsis,
    span.anticon.anticon-double-right.ant-pagination-item-link-icon,
    span.anticon.anticon-double-left.ant-pagination-item-link-icon {
      color: #61687c;
      max-width: 30px;
      background-color: transparent !important;
      padding: 0;
    }

    li.ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon {
      padding: 0;
      background-color: transparent;
      border: none;
    }
  }

  &.not_auth {
    margin: 0 auto;
    padding: 15px;
    max-width: 1335px;
    height: 100vh;
  }
}

@media (max-width: 1540px) {
  .generate_course {
    .trajectory_block {
      /*flex-direction: column;*/

      /*.banner {
        margin-top: 15px;
        max-width: 70%;
      }*/

      .share_link {
        margin-top: 15px;
      }
    }
  }
}

@media (max-width: 1330px) {
  .generate_course {
    .list_planning.unAuthenticated {
      flex-direction: column;

      .planning_course,
      .player {
        margin: 0 auto 15px auto !important;
        display: flex;
        width: 100%;
        flex: 0 0 100%;

        .player_demo {
          height: auto !important;
        }
      }
    }
  }
}

@media (max-width: 1070px) {
  .generate_course {
    .trajectory_block {
      flex-direction: column;

      .trajectory {
        margin: 0 auto 15px auto;
        max-width: 100% !important;
        width: 100%;

        .list_tags {
          max-height: 90px;
        }
      }

      .banner {
        img {
          border-radius: 40px;
        }
      }

      .share_link {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

@media (max-width: 1070px) {
  .generate_course {

    .trajectory_block {
      .trajectory {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 820px) {
  .generate_course {
    .trajectory_block {
      .trajectory {
        max-width: 100% !important;
      }

      .banner {
        max-width: 100% !important;
      }
    }

    .group_header {
      margin-bottom: 15px;
      transition: all .3s linear;
    }

    .list_planning {
      margin-left: 15px;
      margin-top: 15px;
      flex-wrap: nowrap;
      flex-direction: column;
      overflow-x: auto;

      .planning_course:not(.unAuthenticated) {
        border: 1px solid var(--dark);
      }
    }

    .header {
      margin: 0 0 15px 0;
      padding-bottom: 0;
      border-color: transparent;

      &.border_bottom {
        padding-bottom: 15px;
        border-color: var(--background_lighten-dark);
      }
    }

    .list_planning.unAuthenticated {
      margin-top: 25px;
      flex-direction: row;
      margin-left: 0;

      .planning_course {
        border: 1px solid var(--dark);
      }
    }

    .title_planning {
      display: none;
    }

    .player_demo {
      height: initial !important;
    }
  }
}

@media (max-width: 720px) {
  .generate_course {
    .group_header {
      width: 100%;
      padding: 15px 13px;
      border-radius: 0 0 24px 24px;
      background: #1D1E25;

      .trajectory_block {
        margin-bottom: 0;

        .share_link {
          display: none;
        }
      }

      .btn_curl {
        display: flex;
      }
    }

    .trajectory_block {
      margin-bottom: 15px;

      img {
        border-radius: 26px !important;
      }
    }

    .title_block {
      .h1 {
        margin-bottom: 0 !important;
        font-size: 22px;
        line-height: normal;

        svg {
          display: none;
        }
      }

      .text {
        display: none;
      }
    }

    .header_buttons {
      .favorite {
        width: 34px;
        height: 34px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    .generation_count-block {
      padding: 3px 3px 3px 8px !important;

      .count {
        span:first-child {
          font-size: 18px !important;
        }

        span:last-child {
          display: none;
        }
      }

      .icon {
        margin-left: 8px !important;
        padding: 6px 15px !important;

        span {
          display: none;
        }
      }
    }

  }
}
